// Set the colors to blue and amber
$ag-font-family: "Montserrat", "Helvetica", Arial, sans-serif;
$ag-secondary-foreground-color-opacity: 1;
// $ag-selected-color: red;
// $ag-secondary-font-family: "Montserrat", "Helvetica", Arial, sans-serif;

// Import the ag-Grid material theme
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-balham.css";
@import "@ng-select/ng-select/themes/default.theme.css";

/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "material-design-icons/iconfont/material-icons.css";

/* Metronic */
// header base skins
.kt-header-base-light {
	@import "sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
	@import "sass/global/layout/header/skins/base/dark.scss";
}

// header menu skins
.kt-header-menu-light {
	@import "sass/global/layout/header/skins/menu/light.scss";
}

.kt-header-menu-dark {
	@import "sass/global/layout/header/skins/menu/dark.scss";
}

// brand skins
.kt-brand-dark {
	@import "sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
	@import "sass/global/layout/brand/skins/light.scss";
}

// aside skins
.kt-aside-dark {
	@import "sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
	@import "sass/global/layout/aside/skins/light.scss";
}

// Estilos de Compononentes
@import "sass/custom/shared-components.scss";

// Quitar
// body {
// 	width: 100%;
// 	min-height: 100vh;
// 	background-color: #f2f3f8;
// 	overflow: hidden;
// }

.ag-root ::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 8px;
	height: 8px;
}

.ag-root ::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.5);
	box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.ag-theme-material .ag-row-selected {
	color: #ef8157;
	font-weight: 700;
}

.kt-aside-dark .select2-search>.select2-search__field {
	border: none !important;
}

.ng-select.ng-select-single .ng-select-container {
	height: calc(1.5em + 1rem + 2px) !important;
}

.ng-select .ng-select-container {
	min-height: calc(1.5em + 1rem + 2px) !important;
	font-size: 11px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
	font-size: 11px;
}

.nav-tabs {
	margin-bottom: 5px !important;
}

.mat-mdc-dialog-container {
	padding: 0 !important;
	color: #646c9a !important;
}

.mat-mdc-dialog-content {
	padding: 0 !important;
	margin: 0 !important;
}

.kt-portlet {
	margin-bottom: 0 !important;
}

.mat-mdc-dialog-title {
	height: auto !important;
}

.customDisabled {
	pointer-events: none;
	opacity: 0.5;
}

.form-control {
	&[readonly] {
		background-color: #f9f9f9;
	}
}

.subtitle-modal {
	font-size: 0.9rem;
}

.nav-tabs .nav-item .nav-link {
	color: #000 !important;
}

.fieldset-title {
	font-size: 1rem !important;
	color: #000 !important;
	font-weight: bold !important;
	text-align: left !important;
	width: auto !important;
	padding: 0 10px !important;
	border-bottom: none !important;
}

//ESTILOS ADUANA
ng-select.ng-invalid.ng-touched {
	.ng-select-container {
		border-color: #dc3545;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
		// padding-right: calc(1.5em + 1.3rem);
		padding-right: calc(0.55em + 1.3rem);
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fd397a' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fd397a' stroke='none'/%3e%3c/svg%3e");
		background-repeat: no-repeat;
		background-position: right calc(0.375em + 0.325rem) center;
		background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
	}
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
	border-color: #dc3545;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8 !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8 !important;
}

.form-control:focus,
.form-control:active {
	border-color: #007eff;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1) !important;
}

.btn:focus:not(.btn-elevate) {
	border-color: #007eff !important;
	box-shadow: inset 0 28px 20px rgba(0, 0, 0, 0.1), 0 0 0px 5px rgba(0, 126, 255, 0.2) !important;
}

.swal2-popup .swal2-styled:focus {
	border-color: #007eff !important;
	box-shadow: inset 0 28px 20px rgba(0, 0, 0, 0.1), 0 0 0px 5px rgba(0, 126, 255, 0.2) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
	color: #333 !important;
	background-color: #d6edff !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
	background-color: #e8f5ff !important;
	color: #333 !important;
}

.mat-mdc-tab-group {
	font-family: "Soho Gothic Pro", sans-serif;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label {
	font-size: 1rem !important;
	color: #000 !important;
	font-weight: bold !important;
	font-family: "Soho Gothic Pro", sans-serif;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label-active {
	opacity: 1 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-body-content {
	padding-top: 10px !important;
}

.ng-multiselect-checkbox {
	color: #000 !important;
	font-family: "Soho Gothic Pro", sans-serif;
	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
	.mat-checkbox-layout {
		margin-bottom: -4px !important;
		margin-top: -4px !important;
	}
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
.mat-dialog-body-fixed-footer {
	height: calc(100% - 130px);
	max-height: unset;
}

fieldset:focus-within {
	background: #ededeb;
}

// div.ag-root .ag-cell-focus {
//     -webkit-user-select: text;
//     -moz-user-select: text;
//     -ms-user-select: text;
//     user-select: text;
// }
sc-ag-grid-customizable .ag-unselectable .ag-cell{
	-moz-user-select: text !important;
	-webkit-user-select: text !important;
	-ms-user-select: text !important;
	user-select: text !important;
}
ag-grid-angular .ag-unselectable .ag-cell{
	-moz-user-select: text !important;
	-webkit-user-select: text !important;
	-ms-user-select: text !important;
	user-select: text !important;
}

// //AG GRID STYLE
actions-button-cell,
.ag-cell {
	overflow: visible;
}

.ag-cell-value {
	overflow: hidden;
	text-overflow: ellipsis;
}

#toast-container>.customer-info {
	.toast-title {
		font-weight: bold;
		color: white;
		padding-left: 10px;
	}

	.toast-message {
		color: white;
		padding-left: 10px;
	}

	background-color: #f89406;
}

#toast-container>.toast.customer-info {
	background-image: none !important;

	&::before {
		position: relative;
		font-size: 30px;
		line-height: 50px;
		float: left;
		margin-left: -1em;
		color: #fff;
		font-family: Flaticon2;
		content: "\f168";
	}
}

// Scroll
.ps__rail-x,
.ps__rail-y {
	opacity: 0.6;
}

.ag-theme-balham .ag-cell-inline-editing {
	border-color: transparent !important;
	height: 34px;
}

.ag-body-viewport,
.ag-body-horizontal-scroll-viewport {
	position: relative;
}

::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 8px;
	height: 8px;
}

::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.5);
	box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.ag-root-wrapper,
.ag-layout-auto-height,
.ag-layout-auto-height,
.ag-body-viewport-wrapper,
.ag-layout-auto-height {
	overflow: visible !important;
}

textarea {
    resize: none !important;
}

// Icono del toast caso especial
.iconTopPosition {
	background-position: 15px 13px !important;
}

@mixin tooltip-background($color-name) {
	$color-var: unquote("var(--bs-#{$color-name})");
  
	.bg-#{$color-name}:has(>.mdc-tooltip__surface) {
		background-color: inherit !important;
		--bs-bg-opacity: inherit !important;
  
		>.mdc-tooltip__surface {
			--mdc-plain-tooltip-container-color: #{$color-var};
		}
	}
 }
  
  
 @include tooltip-background(primary);
 @include tooltip-background(danger);
 @include tooltip-background(success);
 @include tooltip-background(info);
 @include tooltip-background(warning);
 
 .mat-mdc-dialog-container .mdc-dialog__surface {
	padding: 0px !important;
}
  
 ngb-datepicker-navigation-select>.form-select {
	background-position: right 0.4rem center;
 }
  
 .fieldset-border>legend {
	float: none !important;
 }

 // MAT-CHECKBOX
.mat-mdc-checkbox {
	--mdc-checkbox-state-layer-size: 1.2rem !important;
}

.mdc-checkbox__background {
	width: 1.2rem !important;
	height: 1.2rem !important;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
}

.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
	--mat-menu-item-label-text-font: "Soho Gothic Pro";
	--mat-menu-item-label-text-size: 14px;
}

.mat-mdc-tab {
	--mat-tab-header-label-text-font: "Soho Gothic Pro";
	--mat-tab-header-label-text-tracking: 0px;
	--mat-tab-header-label-text-size: 12px;
	--mat-tab-header-label-text-weight: 700;
	--mat-tab-header-active-label-text-color: black;
	--mat-tab-header-active-focus-label-text-color: black;
}

date-time-picker-editable .input-group-append>button {
	height: 100%;
}

.table {
	--bs-table-striped-bg: transparent;
	--bs-table-bg: transparent;
	--bs-table-accent-bg: transparent;
}

.btn-block {
	display: block;
	width: 100%;
}

.mat-mdc-dialog-container .mdc-dialog__content{
	--mdc-dialog-supporting-text-font: "Soho Gothic Pro";
	--mdc-dialog-supporting-text-size: 12px;
}

.mat-mdc-checkbox {
	--mdc-form-field-label-text-font: Roboto, Helvetica Neue, sans-serif;
	--mdc-form-field-label-text-line-height: 20px;
	--mdc-form-field-label-text-size: 13px;
	--mdc-form-field-label-text-color: #646C9A;
}

html {
	--mdc-dialog-supporting-text-line-height: 17px;
	--mdc-dialog-supporting-text-tracking: 0.000000rem;
}